import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import history from './modules/history'
import profile from './modules/profile'

const debug = process.env.NODE_ENV !== 'production'

const plugins = [
  createPersistedState({
    key: '.admin-juggle.state',
    paths: [
      'profile',
    ]
  })
]

const devPlugins = [
  createLogger()
]

export default createStore({
  modules: {
    history,
    profile,
  },
  strict: debug,
  plugins: plugins.concat(debug ? devPlugins : [])
})
