import {
  doc,
  updateDoc,
} from 'firebase/firestore'
import { auth, db } from '@/lib/firebase'

interface Group {
  id: string
  name: string
}

export interface State {
  lang: string
  group: Group | null
}

const state = (): State => ({
  lang: '',
  group: null,
})

const getters = {
  lang(state: State) {
    return state.lang
  },

  group(state: State) {
    return state.group
  },
}

const actions = {
  // @ts-ignore
  setLang({ commit }, lang: string) {
    commit('setLang', lang)
  },

  // @ts-ignore
  setGroup({ commit }, group: Group) {
    commit('setGroup', group)
  }
}

const mutations = {
  setLang(state: State, lang: string) {
    state.lang = lang

    const uid = auth.currentUser?.uid
    updateDoc(doc(db, `/users/${uid}`), {
      lang
    })
  },

  setGroup(state: State, group: Group) {
    state.group = group
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
