import { initializeApp, getApps } from 'firebase/app'
import {
  getAuth,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  RecaptchaParameters,
} from 'firebase/auth'
import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore'
import {
  getStorage,
  connectStorageEmulator,
} from 'firebase/storage'

import axios from 'axios'

const apps = getApps()
const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}
const firebase = apps.length ? apps[0] : initializeApp(config)

export default firebase
export const auth = getAuth(firebase)
export const db = getFirestore(firebase)
export const storage = getStorage(firebase)

// window.location.hostname === "localhost"
if(process.env.VUE_APP_FIREBASE_EMULATOR == '1') {
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
  console.info('Connect Firebase Emulator suites.')
}

export function configureCaptcha(containerOrId: string, params: RecaptchaParameters = {}) {
  return new RecaptchaVerifier(containerOrId, {
    size: 'invisible',
    ...params,
  }, auth)
}

export async function alreadyInUse(email: string) {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email)
    return methods.length > 0
  } catch(error) {
    return true
  }
}

export async function signUp(email: string, password: string) {
  const creds = await createUserWithEmailAndPassword(auth, email, password)
  await sendEmailVerification(creds.user)
  return creds.user
}

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider()
  const creds = await signInWithPopup(auth, provider)
  return creds.user
}

export async function download(downloadURL: string) {
  return await axios.get(downloadURL, {
    responseType: 'arraybuffer'
  })
}
