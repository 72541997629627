import { createI18n } from 'vue-i18n'
import enUS from './en-US.json'
import jaJP from './ja-JP.json'
import store from '../stores'

const locale = store.getters['profile/lang'] || window.navigator.language || 'ja-JP'
console.debug('Current Locale: %s', locale)

const messages = {
  'en-US': enUS,
  'ja-JP': jaJP,
}

export default createI18n({
  locale,
  messages,
  fallbackLocale: {
    'en': ['en-US'],
    'ja': ['ja-JP'],
    'ja-JP': ['ja-JP'],
    'default': ['ja-JP', 'en-US']
  },
})

const LangDict = {
  '日本語': ['ja-JP', 'ja'],
  'English(US)': ['en-US', 'en'],
} as {[key: string]: string[]}

export function langCode2Readable(lang: string) {
  for(const key in LangDict) {
    const candidates = LangDict[key]
    if(candidates.includes(lang)) { return key }
  }
  return undefined
}
