
import { IonApp, IonContent, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue'
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { QueryClient, useQueryProvider } from 'vue-query'
import { useAuthStore } from "./stores/auth"

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    useQueryProvider(new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 5 * 1000,
          cacheTime: 3 * 1000,
        }
      }
    }))

    const { state } = useAuthStore()
    const store = useStore()
    const route = useRoute()

    const group = store.getters['profile/group']

    const isMenuDisabled = ref(false)
    const selectedIndex = ref(0)
    const appPages = [
      {
        localeKey: 'App.menu.notificationList',
        url: "/NotificationList",
        name: 'format_list_bulleted.svg',
      },
      {
        localeKey: 'App.menu.userList',
        url: "/UserList",
        name: 'format_list_bulleted.svg',
      },
      {
        localeKey: 'App.menu.invitationManagement',
        url: "/InvitationManage",
        name: 'link.svg',
        afterDivider: true,
      },

      {
        localeKey: 'App.menu.statistics',
        url: "/Statistics",
        name: 'analytics.svg',
      },
      // {
      //   localeKey: 'App.menu.payments',
      //   url: "/Payments",
      //   name: 'format_list_bulleted.svg',
      // },

      {
        localeKey: "App.menu.settings",
        url: "/Settings",
        name: 'settings.svg',
      },
    ]

    function setMenu(path: string) {
      const part = path.split('/')[1]
      if (part === undefined) { return }
      const pattern = new RegExp(path)
      selectedIndex.value = appPages.findIndex(page => pattern.test(page.url))
      if(/(SignUp|SelectGroup|SignIn|Password|AdminInvitation|MFA)$/.test(part)) {
        isMenuDisabled.value = true
      } else {
        isMenuDisabled.value = false
      }
    }
    watch(() => route.path, setMenu)
    setMenu(route.path)

    return {
      isMenuDisabled,
      state,
      group,
      selectedIndex,
      appPages,
      isSelected: (url: string) => url === route.path ? 'selected' : '',
    }
  }
});
