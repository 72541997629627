import { inject, InjectionKey, reactive } from 'vue'
import {
  User,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth'

import { auth, db } from '@/lib/firebase'
import { doc, getDoc } from 'firebase/firestore'

interface Claims {
  root?: boolean
  admin?: boolean
  groups?: string[]
  [key: string]: any
}

interface Auth {
  isAuthenticated: boolean
  email: string | null
  emailVerified: boolean
  displayName: string | null
  photoURL: string | null
  mfa: boolean | null
  phoneNumber: string | null
  claims: Claims
  userData: {[key: string]: any}
}

const authStore = () => {
  const state = reactive<Auth>({
    isAuthenticated: false,
    email: null,
    emailVerified: false,
    displayName: null,
    photoURL: null,
    mfa: false,
    phoneNumber: null,
    claims: {},
    userData: {}
  })

  const setUser = (user: User | null, claims: Claims, userData = {}) => {
    state.isAuthenticated = !!user
    if (user) {
      state.email = user.email
      state.emailVerified = user.emailVerified
      state.displayName = user.displayName
      state.photoURL = user.photoURL

      // @ts-ignore
      const mfaInfo = user.reloadUserInfo || user.reloadUserInfo?.mfaInfo[0]
      if(mfaInfo && mfaInfo.phoneInfo) {
        state.mfa = true
        state.phoneNumber = mfaInfo.phoneInfo.replace('+81', '0')
      }
    } else {
      state.email = null
      state.emailVerified = false
      state.displayName = null
      state.photoURL = null
      state.mfa = false
      state.phoneNumber = null
    }
    state.claims = claims
    state.userData = userData
  }

  function refetch() {
    const user = auth.currentUser
    if(user) {
      return Promise.all([
        user.getIdTokenResult(true),
        getDoc(doc(db, `/users/${user.uid}`))
      ])
        .then(result => {
          const [ idTokenResult, userSnap ] = result
          setUser(user, idTokenResult.claims, userSnap.data())
        })
    } else {
      return new Promise((resolve) => resolve(setUser(null, {}, {})))
    }
  }

  async function signIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function signOut() {
    return auth.signOut()
  }

  onAuthStateChanged(auth, user => {
    if(user) {
      Promise.all([
        user.getIdTokenResult(true),
        getDoc(doc(db, `/users/${user.uid}`))
      ])
        .then(result => {
          const [ idTokenResult, userSnap ] = result
          setUser(user, idTokenResult.claims, userSnap.data())
        })
    } else {
      setUser(null, {}, {})
    }
  })

  return {
    state,
    setUser,
    signIn,
    signOut,
    refetch,
  }
}

export default authStore

export type AuthStore = ReturnType<typeof authStore>

export const authStoreKey: InjectionKey<AuthStore> = Symbol('authStore')

export const useAuthStore = () => {
  const store = inject(authStoreKey)
  if (! store) {
    throw new Error(`${authStoreKey} is not provided`)
  }
  return store
}
