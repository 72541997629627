import {
  query,
  doc,
  collection,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  onSnapshot,
  writeBatch,
  Unsubscribe
} from 'firebase/firestore'
import { db } from '@/lib/firebase'

export interface Conditions {
  keyword?: string
  limit?: number
}

interface Footprint {
  hash: string
  publicKey: string
  email: string
  createdAt: number
}

export interface History {
  userId: string
  signer: string
  recipients: string
  fileName: string
  downloadURL: string
  sharedLink: string
  provider: string
  count: number
  hash: string
  useRecoveryKey: boolean
  revoked: boolean
  footprintHash?: string
  footprints?: Footprint[]
  touchedAt?: number
  createdAt: number | Date // TODO: 要検討
  visible: boolean
}

interface State {
  current: History | null
  items: History[]
  hasMore: boolean
}

const state = (): State => ({
  current: null,
  items: [],
  hasMore: true
})

const getters = {
  current: (state: State) => {
    return state.current
  },
  last: (state: State) => {
    return state.items[state.items.length - 1]
  },
  items: (state: State) => {
    return [...state.items].sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
  },
  hasMore: (state: State) => {
    return state.hasMore
  },
}

let unsubscribe: Unsubscribe

const actions = {
  // @ts-ignore
  clearList({ commit }) {
    commit('setHasMore', true)
    commit('clearItems')
  },

  // @ts-ignore
  loadList({ commit, getters }, { uid, conditions }: { uid: string, conditions: Conditions = {} }) {
    let q = query(
      collection(db, `users/${uid}/files`),
      orderBy('createdAt', 'desc')
    )

    if(conditions.keyword) {
      commit('setHasMore', false)
      commit('clearItems');
      q = query(q,
        where('_token', 'array-contains', conditions.keyword.toLowerCase()),
        limit(conditions.limit || 200)
      )
    } else {
      commit('setHasMore', true)
      q = query(q,
        limit(conditions.limit || 50)
      )
      if(getters.last && getters.last.createdAt) {
        q = query(q,
          startAfter(getters.last.createdAt)
        )
      }
    }

    return getDocs(q)
      .then(snap => {
        // TODO:
        return snap.docs.map(doc => {
          const history = { ...doc.data(), userId: uid } as History
          // TODO: Firebaseのtimestamp型の扱いをうまくやりたい
          // @ts-ignore
          history.createdAt = new Date(history.createdAt.seconds * 1000)
          commit('pushItem', history)
          return history
        })
      })
      .then(histories => {
        if(! conditions.keyword) {
          commit('setHasMore', histories.length !== 0)
        }
      })
  },

  // @ts-ignore
  load({ commit }, { uid, hash }: { uid: string, hash: string }) {
    const docRef = doc(collection(db, `/users/${uid}/files`), hash)
    unsubscribe = onSnapshot(docRef, snap => {
      const history = { ...snap.data(), userId: uid } as History
      // TODO: Firebaseのtimestamp型の扱いをうまくやりたい
      // @ts-ignore
      history.createdAt = new Date(history.createdAt.seconds * 1000)
      commit('setItem', history)
    })
  },

  // @ts-ignore
  unload({ commit }) {
    commit('setItem', null)
    // @ts-ignore
    if(unsubscribe) { unsubscribe(); unsubscribe = null }
  }
}

const mutations = {
  pushItem(state: State, item: History) {
    state.items.push(item)
  },

  updateItem(state: State, item: Partial<History>) {
    const index = state.items.findIndex(item => item.hash === item.hash)
    if(index === -1) { return }
    const newItems = [ ...state.items ]
    newItems[index] = { ...newItems[index], ...item }
    state.items = newItems
  },

  removeItem(state: State, item: History) {
    const newItems = state.items.filter(item => item.hash !== item.hash)
    state.items = newItems
  },

  setItems(state: State, items: History[]) {
    state.items = items
  },

  clearItems(state: State) {
    state.items = []
  },

  setItem(state: State, item: History) {
    state.current = item
  },

  setHasMore(state: State, flag: boolean) {
    state.hasMore = flag
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
