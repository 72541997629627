import { createApp } from 'vue'
import { initializeApp, getApps } from 'firebase/app'

import { IonicVue } from '@ionic/vue'
import authStore, { authStoreKey } from '@/stores/auth'
import store from '@/stores'

import App from './App.vue'
import router from './router'
import i18n from '@/locales'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}
const apps = getApps()
apps.length ? apps[0] : initializeApp(config)

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.css';

const app = createApp(App)
  .use(IonicVue, { mode: 'md' })
  .use(router)
  .use(store)
  .use(i18n)
;

app.provide(authStoreKey, authStore())

router.isReady().then(() => {
  app.mount('#app');
});
