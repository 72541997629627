import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import { onAuthStateChanged } from 'firebase/auth'

import store from '../stores'
import { auth } from '@/lib/firebase'

const routes: Array<RouteRecordRaw> = [
  {
    meta: { requiresAuth: true },
    path: '/NotificationList',
    component: () => import('../views/NotificationList.vue'),
  },
  {
    meta: { requiresAuth: true },
    path: '/Notification/:uid',
    component: () => import('../views/Notification.vue'),
  },

  {
    meta: { requiresAuth: true },
    path: '/UserList',
    component: () => import('../views/UserList.vue'),
  },
  {
    meta: { requiresAuth: true },
    path: '/User/:uid',
    component: () => import('../views/User.vue'),
  },

  {
    meta: { requiresAuth: true },
    path: '/InvitationManage',
    component: () => import('../views/InvitationManage.vue'),
  },

  {
    meta: { requiresAuth: true },
    path: '/Statistics',
    component: () => import('../views/Statistics.vue'),
  },
  {
    meta: { requiresAuth: true },
    path: '/Payments',
    component: () => import('../views/Payments.vue'),
  },
  {
    meta: { requiresAuth: true },
    path: '/Settings',
    component: () => import('../views/Settings.vue'),
  },

  {
    path: '/AdminInvitation/:id',
    component: () => import('../views/AdminInvitation.vue'),
  },
  {
    meta: { requiresAuth: true },
    path: '/MFA',
    component: () => import('../views/MFA.vue'),
  },
  {
    meta: { requiresAuth: true },
    path: '/SelectGroup',
    component: () => import('../views/SelectGroup.vue')
  },
  {
    path: '/Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/Terms',
    component: () => import('../views/Terms.vue')
  },

  {
    path: '/SignIn',
    component: () => import('../views/SignIn.vue'),
  },
  {
    path: '/ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '',
    redirect: '/UserList'
  },
  {
    path: '/:catchAll(.*)',
    // @ts-ignore
    beforeEnter: () => { window.location = '/' },
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const {
    group,
  // @ts-ignore
  } = store.state.profile

  if(to.matched.some(record => record.meta.requiresAuth)) {
    console.debug('requiresAuth')
    const unsub = onAuthStateChanged(auth, user => {
      if(user) {
        // @ts-ignore
        const notYetSetMFA = !(user.reloadUserInfo?.mfaInfo)
        if(notYetSetMFA) {
          console.debug('Not yet Set MFA')
          // MFA未設定ならば `/MFA` へ飛ばす
          if(to.path.startsWith('/MFA')) {
            next()
          } else {
            next({ path: '/MFA'})
          }
        } else if(! group) {
          console.debug('not group set')
          if(to.path.startsWith('/SelectGroup')) {
            next()
          } else {
            next({ path: '/SelectGroup' })
          }
        } else {
          console.debug('group set')
          // `groupID`がセットされていればアプリケーションが利用可能な状態とする
          if(/(MFA|AdminInvitation\/)/.test(to.path)) {
            next({ path: '/UserList' })
          } else {
            next()
          }
        }
      } else {
        // 未ログイン時
        next({ path: '/SignIn', query: { redirect: to.fullPath } })
      }
      unsub()
    })
  } else {
    console.debug('no requiresAuth')
    // ログイン済みの場合はリダイレクトさせる
    if(/(SignIn|MFA|AdminInvitation\/)/.test(to.path)) {
      // console.debug('SignIn|AdminInvitation|MFA')
      const unsub = onAuthStateChanged(auth, async (user) => {
        if(user) {
          next({ path: '/UserList' })
        } else {
          next()
        }
        unsub()
      })
    } else {
      next()
    }
  }
})

export default router
